import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"

// SVG
import SvgLogo from "./svg/header/header-logo-svg"
import SvgMenu from "./svg/header/header-menu-svg"
import SvgClose from "./svg/header/header-close-svg"

const staticHeader = {
  hidden: {
    opacity: 0,
    y: "-100%",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
    },
  },
}

const dynamicHeader = {
  hidden: {
    y: "-100%",
  },
  visible: {
    y: 0,
  },
}

const Header = () => {
  const navLinks = [
    { name: "Home", to: "/" },
    { name: "Services", to: "/services" },
    // { name: "Case Studies", to: "/case-studies" },
    // { name: "Blog", to: "https://engineering.unicornbox.io/" },
    { name: "Our Company", to: "/our-company" },
  ]
  const [currentPage, setCurrentPage] = useState(null)
  const [showReactiveHeader, setShowReactiveHeader] = useState(false)
  const [prevScroll, setPrevScroll] = useState(
    typeof window === "undefined" ? 0 : window.scrollY
  )
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  useEffect(() => {
    setCurrentPage(window.location.pathname)

    const handleScroll = () => {
      const currentScroll = window.scrollY

      if (currentScroll > window.innerHeight) {
        if (currentScroll > prevScroll) {
          setShowReactiveHeader(false)
        } else {
          setShowReactiveHeader(true)
        }
      } else {
        setShowReactiveHeader(false)
      }

      setPrevScroll(currentScroll)
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [prevScroll])

  const scrollToTop = () => {
    if (typeof document !== "undefined" && typeof window !== "undefined") {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    }
  }

  const addHoverStyle = e => {
    e.target.style.color = "#372fd9"
  }

  const removeHoverStyle = e => {
    if (
      typeof window !== "undefined" &&
      e.target.pathname === window.location.pathname
    ) {
      e.target.style.color = "#372fd9"
    } else {
      e.target.style.color = "#000"
    }
  }

  const isActive = ({ isCurrent }) => {
    return isCurrent
      ? {
          className:
            "hidden lg:block text-small font-medium font-paragraph text-brand",
        }
      : {
          className:
            "hidden lg:block text-small font-medium font-paragraph text-black",
        }
  }

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? {
          className:
            "hidden lg:block text-small font-medium font-paragraph text-brand",
        }
      : {
          className:
            "hidden lg:block text-small font-medium font-paragraph text-black",
        }
  }

  const isActiveOnMobileMenu = ({ isCurrent }) => {
    return isCurrent
      ? { className: "text-h5 font-bold font-heading text-brand" }
      : { className: "text-h5 font-bold font-heading text-black" }
  }

  const isPartiallyActiveOnMobileMenu = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? { className: "text-h5 font-bold font-heading text-brand" }
      : { className: "text-h5 font-bold font-heading text-black" }
  }

  return (
    <AnimatePresence>
      {/* Static Header */}
      {currentPage && (
        <motion.header
          key="staticHeader"
          variants={currentPage === "/" ? staticHeader : null}
          initial="hidden"
          animate="visible"
          className="flex justify-between items-center p-5 md:p-12.5 -z-1"
        >
          <Link to="/" onClick={scrollToTop}>
            <SvgLogo className="w-auto h-10" />
          </Link>

          <nav className="ml-10 grid grid-rows-1 grid-flow-col gap-x-18 place-items-center">
            {navLinks.map((navLink, index) => {
              return navLink.to.charAt(0) === "/" ? (
                <Link
                  key={index}
                  to={navLink.to}
                  className="hidden lg:block text-small font-medium font-paragraph text-black"
                  getProps={navLink.to === "/" ? isActive : isPartiallyActive}
                  onMouseEnter={addHoverStyle}
                  onMouseLeave={removeHoverStyle}
                >
                  {navLink.name}
                </Link>
              ) : (
                <a
                  key={index}
                  href={navLink.to}
                  className="hidden lg:block text-small font-medium font-paragraph text-black"
                  onMouseEnter={addHoverStyle}
                  onMouseLeave={removeHoverStyle}
                >
                  {navLink.name}
                </a>
              )
            })}

            <Link
              to="/contact"
              onClick={scrollToTop}
              className="hidden lg:block py-4 px-5 bg-brand text-small font-medium font-paragraph text-white"
            >
              Start a project?
            </Link>

            <button
              className="block lg:hidden w-5 h-4"
              aria-label="Open navigation menu"
              onClick={() => setShowMobileMenu(true)}
            >
              <SvgMenu />
            </button>
          </nav>
        </motion.header>
      )}

      {/* Dynamic Header */}
      {showReactiveHeader && (
        <motion.header
          key="dynamicHeader"
          variants={dynamicHeader}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ duration: 0.4 }}
          className="bg-white fixed top-0 w-full flex justify-between items-center p-5 md:px-12.5 md:py-5 shadow-sm z-50"
        >
          <Link to="/" onClick={scrollToTop}>
            <SvgLogo className="w-auto h-10" />
          </Link>

          <nav className="ml-10 grid grid-rows-1 grid-flow-col gap-x-18 place-items-center">
            {navLinks.map((navLink, index) => {
              return navLink.to.charAt(0) === "/" ? (
                <Link
                  key={index}
                  to={navLink.to}
                  className="hidden lg:block text-small font-medium font-paragraph text-black"
                  getProps={navLink.to === "/" ? isActive : isPartiallyActive}
                  onMouseEnter={addHoverStyle}
                  onMouseLeave={removeHoverStyle}
                >
                  {navLink.name}
                </Link>
              ) : (
                <a
                  key={index}
                  href={navLink.to}
                  className="hidden lg:block text-small font-medium font-paragraph text-black"
                  onMouseEnter={addHoverStyle}
                  onMouseLeave={removeHoverStyle}
                >
                  {navLink.name}
                </a>
              )
            })}

            <Link
              to="/contact"
              className="hidden lg:block py-4 px-5 bg-brand text-small font-medium font-paragraph text-white"
            >
              Start a project?
            </Link>

            <button
              className="block lg:hidden w-5 h-4"
              aria-label="Open navigation menu"
              onClick={() => setShowMobileMenu(true)}
            >
              <SvgMenu />
            </button>
          </nav>
        </motion.header>
      )}

      {/* Mobile Navigation */}
      {showMobileMenu && (
        <motion.div
          key="mobileNav"
          initial={{ x: "100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { duration: 0.4 } }}
          exit={{
            opacity: 0,
            transition: { duration: 0.2 },
          }}
          className="fixed top-0 left-0 w-full h-screen bg-white z-50 flex flex-col"
        >
          <button
            onClick={() => setShowMobileMenu(false)}
            className="absolute top-0 right-0 h-10 m-5 md:m-12.5"
          >
            <SvgClose />
          </button>
          <div className="w-full h-full flex flex-col justify-center items-center space-y-6 ">
            {navLinks.map((navLink, index) => (
              <Link
                key={index}
                to={navLink.to}
                onClick={() => {
                  scrollToTop()
                  setShowMobileMenu(false)
                }}
                className="text-h5 font-bold font-heading text-black"
                getProps={
                  navLink.to === "/"
                    ? isActiveOnMobileMenu
                    : isPartiallyActiveOnMobileMenu
                }
              >
                {navLink.name}
              </Link>
            ))}
            <Link
              to="/contact"
              onClick={() => {
                scrollToTop()
                setShowMobileMenu(false)
              }}
              className="py-4 px-5 bg-brand text-h5 font-bold font-heading text-white"
            >
              Start a Project?
            </Link>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Header
