import React, { useEffect, useState } from "react"
import { motion, useTransform, useViewportScroll } from "framer-motion"
// Imports with Case Studies
// import { graphql, Link, useStaticQuery } from "gatsby"
// Imports without Case Studies
import { Link } from "gatsby"

// SVG
import FooterLogo from "./svg/footer/footer-logo-svg"

const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     allMarkdownRemark(sort: { fields: [frontmatter___year], order: DESC }) {
  //       edges {
  //         node {
  //           id
  //           frontmatter {
  //             title
  //             year
  //           }
  //           fields {
  //             slug
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  const [currentPage, setCurrentPage] = useState(null)
  const [showBlueBlock, setShowBlueBlock] = useState(false)
  const { scrollYProgress } = useViewportScroll()
  const yHeading = useTransform(scrollYProgress, [0.94, 0.98], [30, 0])
  const [isMobile, setIsMobile] = useState(
    typeof window === "undefined"
      ? false
      : window.innerWidth <= 768
      ? true
      : false
  )

  useEffect(() => {
    setCurrentPage(window.location.pathname)

    currentPage === "/contact" || currentPage === "/contact/"
      ? setShowBlueBlock(false)
      : setShowBlueBlock(true)

    if (currentPage === "/") {
      if (window.innerWidth <= 768) {
        setIsMobile(true)
      }
    }
  }, [currentPage])

  const cols = [
    {
      title: "UnicornBox",
      items: [
        { name: "Home", to: "/" },
        { name: "Services", to: "/services" },
        // { name: "Case Studies", to: "/case-studies" },
        // { name: "Blog", to: "https://engineering.unicornbox.io/" },
        { name: "Our Company", to: "/our-company" },
      ],
    },
    {
      title: "Services",
      items: [
        { name: "Web Development", to: "/services#web-development" },
        { name: "Mobile Development", to: "/services#mobile-development" },
        { name: "Consulting", to: "/services#consulting" },
      ],
    },
    // { title: "Case Studies", items: [...data.allMarkdownRemark.edges] },
    {
      title: "Contact Us",
      items: [{ name: "Start a project", to: "/contact" }],
    },
  ]

  return (
    <div>
      {currentPage && showBlueBlock && (
        <div className="flex flex-col items-center bg-brand pt-20 pb-22">
          <motion.h1
            style={{ y: isMobile ? 0 : currentPage === "/" ? yHeading : 0 }}
            className="mx-10 text-center mb-2 text-white text-h3 md:text-h1 font-heading font-bold"
          >
            Want to work together?
          </motion.h1>
          <motion.h5
            style={{ y: isMobile ? 0 : currentPage === "/" ? yHeading : 0 }}
            className="mx-10 text-center mb-14 text-white text-regular md:text-h5 font-heading font-normal"
          >
            We would love to work with you to make your idea happen.
          </motion.h5>
          <Link
            to="/contact"
            className="whitespace-nowrap text-small font-paragraph font-medium bg-white text-brand py-4 px-5"
          >
            Start a project
          </Link>
        </div>
      )}

      <div className="flex flex-wrap justify-between w-full bg-black text-white pt-20 pb-10">
        <div className="mx-10 md:mx-22 mb-10 w-55">
          <FooterLogo className="h-9 mb-8" />
          <p className="mb-5 text-white text-opacity-50 text-small font-paragraph font-normal whitespace-nowrap">
            © 2020 UnicornBox Technologies Inc.
          </p>
          <div className="text-white text-opacity-50 text-small font-paragraph font-normal">
            <p>Mission Hills Subdivision</p>
            <p>Antipolo City, Rizal</p>
            <p>Philippines 1870</p>
            <a href="tel:+63285696530">(+632) 856 96530</a>
          </div>
        </div>
        <div className="flex flex-wrap mr-25">
          {cols.map((col, index) => (
            <div key={index} className="ml-10 md:ml-22 mb-10 space-y-2">
              <p className="whitespace-nowrap uppercase mb-2 text-white text-smallHeading tracking-widest font-paragraph font-medium">
                {col.title}
              </p>
              {col.title === "Case Studies"
                ? col.items.map(({ node }) => (
                    <p
                      key={node.id}
                      className="whitespace-nowrap text-white text-opacity-70 text-small font-paragraph font-normal"
                    >
                      <Link to={node.fields.slug}>
                        {node.frontmatter.title}
                      </Link>
                    </p>
                  ))
                : col.items.map((item, index) => (
                    <p
                      key={index}
                      className="whitespace-nowrap text-white text-opacity-70 text-small font-paragraph font-normal"
                    >
                      {item.to.charAt(0) === "/" ? (
                        <Link to={item.to}>{item.name}</Link>
                      ) : (
                        <a key={index} href={item.to}>
                          {item.name}
                        </a>
                      )}
                    </p>
                  ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Footer
